import React from "react";
import "./Projects.css";
import "./Background.css";
import './TechStack.css';

export const TechStack = () => {
  return (
    <>
       <div  className="tech-background">
        <h2 className="headingTech spaceStyleHeadingTech">Tech Stack</h2>
        <div>
            <p className='subHeadingTech spaceStyleSubheadingTech'>Languages</p>
            <p className='spaceStyleTextTech'> Python | JavaScript/TypeScript | SQL | C/C++ | R </p>
        </div>
        <div>
            <p className='subHeadingTech spaceStyleSubheadingTech'>Frameworks </p>
            <p className='spaceStyleTextTech'> React | Next | Node.js | Flask | FastAPI | CSS </p>
        </div>
        <div>
            <p className='subHeadingTech spaceStyleSubheadingTech'> DB & Developer Tools </p>
            <p className='spaceStyleTextTech'> Firebase | MongoDB | Excel | AWS | Google Cloud Platform | Linux | Git </p>
        </div>
        <div>
            <p className='subHeadingTech spaceStyleSubheadingTech'> Data Science Libraries </p>
            <p className='spaceStyleTextTech'> Pandas | SciPy/NumPy | Matplotlib | Scikit-learn | Tensorflow | OCR | OpenCV </p>
        </div>

        </div>
    </>
  );
};
