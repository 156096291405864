import React from "react";
import "./Blog.css";

export const Blog = () => {
  return (
    <>
      <div id="experience" className="blog-background">
        <h2 className="blog-heading spaceStyleSubheadingBlog">Blog coming when I stop sleeping all day. <br/><br/><i>"Kuch Pane ke liye kuch khona pardta hain"</i></h2>

      </div>
    </>
  );
};
