import React from "react";
import "./Projects.css";
import "./Background.css";

export const Projects = () => {
  return (
    <>
      <div id="experience" className="experience-background">
        <h2 className="heading spaceStyleSubheading">Major Work Experiences</h2>
        <div id="project-template">
          <div className="project-item" data-date="May 2024 – Present">
            <span className="project-date spaceStyleText">May 2024 – Present</span>
            <h3 className="project-title spaceStyleText">Quantitative Trader</h3>
            <h4 className="project-company spaceStyleText">
              <a href="https://www.rhiconccy.com/ ">
                Rhicon Currency Management - Hedge Fund with AUM $400M+
              </a>
            </h4>
            <p className="project-description spaceStyleText">
              • Developed a trading portfolio with 3 breakout & reversion strategies using advanced statistical techniques for 6 FX pairs <br /> <br/>
              • Backtested & optimised medium frequency trading strategies with annualized returns of 22% over 8 years, outperforming traditional ETFs by 12% <br /> <br/>
              • Integrating developed trading strategies to paper trading framework using WebSockets for confirmation of signal accuracy
            </p>
          </div>
          <div className="project-item">
            <span className="project-date spaceStyleText">Jan 2024 - Present</span>
            <h3 className="project-title spaceStyleText">Co-Founder & Software Engineer</h3>
            <h4 className="project-company spaceStyleText">
              <a href="https://bloomfin.vercel.app/">Finspire - Blockchain based Micro-financing platform</a>
            </h4>
            <p className="project-description spaceStyleText">
              • Pitched microfinancing blockchain application to investors achieving equity free funding from Ripple <br /> <br/>
              • Developed smart contracts on XRPL chain for the application’s microloan transfers improving transactional efficiency by 3x <br /> <br/>
              • Trained credit evaluation ML models on financial and psychometric user profiles achieving 89% test accuracy
            </p>
          </div>
          <div className="project-item">
            <span className="project-date spaceStyleText">Jan 2024 - Mar 2024</span>
            <h3 className="project-title spaceStyleText">Software Engineer — Open Source Contributor</h3>
            <h4 className="project-company spaceStyleText">
              <a href="https://camicroscope.org/">caMicroscope - Medical Analytics for Cancer Researchers</a>
            </h4>
            <p className="project-description spaceStyleText">
              • Debugged asynchronous tile fetching errors via OpenSeaDragon improving cell visualisation to enhance user experience
            </p>
          </div>
          <div className="project-item">
            <span className="project-date spaceStyleText">Aug 2023 - Jan 2024</span>
            <h3 className="project-title spaceStyleText">Data Scientist</h3>
            <h4 className="project-company spaceStyleText"><a href="https://lkyspp.nus.edu.sg/our-people/faculty/ba-yuhao">
              Research work at NUS with Prof. Yuhao Ba</a>
            </h4>
            <p className="project-description spaceStyleText">
              • Developed data crawlers to retrieve posts from Facebook collating 12+ datasets & enhancing data retrieval efficiency by 3x <br /> <br/>
              • Trained a latent dirichlet allocation topic modeller & created a network analysis through directed acyclic graphs producing 20+ new actionable insights on organisational collaboration among NGOs in SE Asia
            </p>
          </div>

          <div className="project-item">
            <span className="project-date spaceStyleText">May 2023 - Mar 2024</span>
            <h3 className="project-title spaceStyleText">Machine Learning Technology Lead</h3>
            <h4 className="project-company spaceStyleText">
              <a href="https://fintechsociety.comp.nus.edu.sg/">NUS Fintech Society</a>
            </h4>
            <p className="project-description spaceStyleText">
              • Led a team of 7 analysts to create a stock trading platform using regression models to predict stock returns on daily bars <br /> <br/>
              • Deployed a 93% accurate random forest classifier to detect fake financial news circulated on the internet, significantly reducing the spread of misinformation and protecting investors from potential fraud.
            </p>
          </div>
         

  

       
        </div>
      </div>
    </>
  );
};
