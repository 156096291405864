import logo from './logo.svg';
import './App.css';
import './Background.css';
import { HomePage } from './MyComponents/BackgroundTest';
import { Projects } from './MyComponents/Projects';
import {useState} from 'react';
import ImageButton from './MyComponents/ImageButton'; 
import { TechStack } from './MyComponents/TechStack';
import { Portfolio } from './MyComponents/Portfolio';
import { Blog } from './MyComponents/Blog';
import { GetInTouch } from './MyComponents/GetInTouch';

const HomeComponent = () => <div><HomePage/></div>;
const ProjectsComponent = () => <div><Projects/></div>;
const TechStackComponent = () => <div><TechStack/></div>;
const PortfolioComponent = () => <div><Portfolio/></div>
const BlogComponent = () => <div><Blog/></div>;
const GetInTouchComponent = () => <div><GetInTouch/></div>;

function App() {
  const [currentComponent, setCurrentComponent] = useState('home'); // Default to 'home' component

  const [isHovered, setIsHovered] = useState(false);

  const playSound = () => {
    setIsHovered(true);
  };

  const stopSound = () => {
    setIsHovered(false);
  };

  const handleProjectChange = () => {
    setCurrentComponent('projects'); 
  };

  const handleHomeChange = () => {
    setCurrentComponent('home');
  };
  
  const handleTechStackChange = () => {
    setCurrentComponent('techstack');
  };

  const handlePortfolioChange = () => {
    setCurrentComponent('portfolio');
  };

   const handleBlogChange = () => {
    setCurrentComponent('blog');
   };

   const handleGetInTouchChange = () => {
    setCurrentComponent('getintouch');
   }

  return (
    <>
      <div className="background">
        <div id="container-stars">
          <div id="stars"></div>
        </div>
        <div id="glow">
          <div className="circle"></div>
          <div className="circle"></div>
        </div>

        <div className="navigationMenu">
          <strong
            onMouseEnter={playSound}
            onMouseLeave={stopSound}
            onClick={handleHomeChange}
            id="homeButton"
            className="spaceStyle"
          >
            <a>
              Home
              {isHovered && (
                <audio autoPlay>
                  <source src="fast-whoosh.mp3" type="audio/mpeg" />{" "}
                  {/* Replace "hover-sound.mp3" with the path to your audio file */}
                </audio>
              )}
            </a>
          </strong>
          <strong
            onMouseEnter={playSound}
            onMouseLeave={stopSound}
            onClick={handleProjectChange}
            id="homeButton"
            className="spaceStyle"
          >
            <a>
              Work Experience
              {isHovered && (
                <audio autoPlay>
                  <source src="fast-whoosh.mp3" type="audio/mpeg" />{" "}
                  {/* Replace "hover-sound.mp3" with the path to your audio file */}
                </audio>
              )}
            </a>
          </strong>
          <strong
            onMouseEnter={playSound}
            onMouseLeave={stopSound}
            onClick={handleTechStackChange}
            id="homeButton"
            className="spaceStyle"
          >
            <a>
              Tech Stack
              {isHovered && (
                <audio autoPlay>
                  <source src="fast-whoosh.mp3" type="audio/mpeg" />{" "}
                  {/* Replace "hover-sound.mp3" with the path to your audio file */}
                </audio>
              )}
            </a>
          </strong>
          <strong
            onMouseEnter={playSound}
            onMouseLeave={stopSound}
            onClick={handlePortfolioChange}
            id="homeButton"
            className="spaceStyle"
          >
            <a>
              Projects
              {isHovered && (
                <audio autoPlay>
                  <source src="fast-whoosh.mp3" type="audio/mpeg" />{" "}
                  {/* Replace "hover-sound.mp3" with the path to your audio file */}
                </audio>
              )}
            </a>
          </strong>
          <strong
            onMouseEnter={playSound}
            onMouseLeave={stopSound}
            onClick={handleBlogChange}
            id="homeButton"
            className="spaceStyle"
          >
            <a>
              Blog
              {isHovered && (
                <audio autoPlay>
                  <source src="whoosh.mp3" type="audio/mpeg" />{" "}
                  {/* Replace "hover-sound.mp3" with the path to your audio file */}
                </audio>
              )}
            </a>
          </strong>
          <strong
            onMouseEnter={playSound}
            onMouseLeave={stopSound}
            onClick={handleGetInTouchChange}
            id="homeButton"
            className="spaceStyle"
          >
            <a>
              Get in Touch?
              {isHovered && (
                <audio autoPlay>
                  <source src="fast-whoosh.mp3" type="audio/mpeg" />{" "}
                  {/* Replace "hover-sound.mp3" with the path to your audio file */}
                </audio>
              )}
            </a>
          </strong>
        </div>

        <div>
          {currentComponent === 'home' && <HomeComponent />}
          {currentComponent === 'projects' && <ProjectsComponent />}
          {currentComponent === 'techstack' && <TechStackComponent />}
          {currentComponent === 'portfolio' && <PortfolioComponent />}
          {currentComponent === 'blog' && <BlogComponent />}
          {currentComponent === 'getintouch' && <GetInTouchComponent />}
        </div>

      </div>
    </>
  );
}

export default App;

