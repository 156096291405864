import React from "react";
import "./Background.css";
import "./HomePage.css";
import ImageButton from "./ImageButton";
import { useState } from "react";
import {Image} from './Image';

export const HomePage = () => {

  const handleGithub = () => {
    window.location.href = 'https://github.com/YuvBindal'
  };
  const handleYoutube = () => {
    window.location.href = 'https://www.youtube.com/@yuvbindal1226'
  };

  const handleDevpost = () => {
    window.location.href = 'https://devpost.com/yuv2bindal'

  }
  const handleLinkedin = () => {
    window.location.href= 'https://www.linkedin.com/in/yuvbindal/'
  }
  const handleInstagram = () => {
    window.location.href= 'https://www.instagram.com/yuvbindal/'
  }


  return (

  <>
    <div id='experience' className="home-background">
      <Image className="profilePicture" imageSrc={process.env.PUBLIC_URL + "/images/devyuv.jpg"}/>

        <h2 className="spaceStyleHeading nameBanner">YUV BINDAL</h2>
        <p className="spaceStyleSubheading">
          Technoprenuer | Software Engineer
        </p>
        <p className="spaceStyleText">
          Hey! I am Yuv, a Y3 EECS Penultimate Year student at NUS. I am passionate about
          finding innovative solutions to global issues. I specialise in
          domains such as <i>Software Engineering</i>, <i>Data Science</i>, and{" "}
          <i>Web3</i> technologies.<br/> 

          I'm open to opportunites in these fields. Feel free to reach out to me!
        </p>
        <div>
            <ImageButton
              imageSrc={process.env.PUBLIC_URL + "/githublogo.png"}
              altText="Button Image"
              onClick={handleGithub}
            />
            <ImageButton
              imageSrc={process.env.PUBLIC_URL + "/devpostlogo.png"}
              altText="Button Image"
              onClick={handleDevpost}
            />
            <ImageButton
              imageSrc={process.env.PUBLIC_URL + "/youtubelogo.png"}
              altText="Button Image"
              onClick={handleYoutube}
            />
            <ImageButton
              imageSrc={process.env.PUBLIC_URL + "/instagramlogo.png"}
              altText="Button Image"
              onClick={handleInstagram}
            />
            <ImageButton
              imageSrc={process.env.PUBLIC_URL + "/linkedinlogo.png"}
              altText="Button Image"
              onClick={handleLinkedin}
            />
      </div>



    </div>

  </>
  );
};
