// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBDfA8Y_7vBlvZus5dNpGItbxGt89da3GY",
  authDomain: "personalwebpage-c5a25.firebaseapp.com",
  projectId: "personalwebpage-c5a25",
  storageBucket: "personalwebpage-c5a25.appspot.com",
  messagingSenderId: "703263794249",
  appId: "1:703263794249:web:a46d0d537f1309e8960b98",
  measurementId: "G-QXJG9QP0J1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

export { db };
