import React, { useState } from "react";
import { db } from "../firebase"; // Adjust the import path as needed
import { collection, addDoc } from "firebase/firestore";
import "./GetInTouch.css";

export const GetInTouch = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');
    setSuccess('');

    try {
      await addDoc(collection(db, "contacts"), formData);
      setSuccess('Your message has been sent successfully! I will get in touch with you shortly. Thanks for reaching out!');
      setFormData({
        name: '',
        email: '',
        message: '',
      });
      
    } catch (error) {
      console.error("Error adding document: ", error);
      setError('There was an error sending your message. Please try again later.');
    }

    setIsSubmitting(false);
  };

  return (
    <>
      <div id="experience" className="touch-background">
        <h2 className="headingTouch spaceStyleHeadingTouch">Get In Touch</h2>
        <form className="contact-form" onSubmit={handleSubmit}>
          <div className="form-group spaceStyleTextTouch">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group spaceStyleTextTouch">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group spaceStyleTextTouch">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              rows="5"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <div className="submit-button-container">
            <button type="submit" className="submit-button spaceStyleTextTouch" disabled={isSubmitting}>
              {isSubmitting ? 'Sending...' : 'Send'}
            </button>
          </div>
          {error && <p className="error-message">{error}</p>}
          {success && <p className="success-message">{success}</p>}
        </form>
      </div>
    </>
  );
};
