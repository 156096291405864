import React from "react";
import "./Portfolio.css";

export const Portfolio = () => {
  return (
    <>
      <div id="experience" className="portfolio-background">
        <h2 className="portfolio-heading spaceStyleHeadingPortfolio">Portfolio Projects</h2>
        <div className="portfolio-container">
          <div className="portfolio-item">
            <div className="portfolio-image">
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/NkZbLYc5UZ8"
                title="YouTube video player"
                frameBorder={0}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div className="portfolio-description">
              <h3 className="spaceStyleSubheadingPortfolio">Finspire - Microfinancing Application</h3>
              <p className="spaceStyleTextPortfolio">Finspire is a web based microfinancing application deployed on the ETH and XRPL test chains. The aim of this project is to improve financial inclusion in underprivileged and underbanked sectors of developing economies. The project has received funding from Ripple and incubated with NUS and AIDF.</p>
            </div>
          </div>
          {/* Add more project items as needed */}
          <div className="portfolio-item">
            <div className="portfolio-image">
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/cMu2bqR9fJ0"
                title="YouTube video player"
                frameBorder={0}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div className="portfolio-description">
              <h3 className="spaceStyleSubheadingPortfolio">Anti Harassment Mobile Application</h3>
              <p className="spaceStyleTextPortfolio">EmpowerNUS aims at curbing crime in regional areas. Keeping our users safe is EmpowerNUS' top priority. This application uses state of the art computer vision algorithms to alert local authorities and loved ones when the user senses they are in danger from the simple press of a button. This application was recognised by NUS School of Computing.</p>
            </div>
          </div>
          <div className="portfolio-item">
            <div className="portfolio-image">
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/imxR1tIZMvY"
                title="YouTube video player"
                frameBorder={0}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div className="portfolio-description">
              <h3 className="spaceStyleSubheadingPortfolio">Expenditure Tracking Web Application</h3>
              <p className="spaceStyleTextPortfolio">We were given 48 hours to develop a web application themed around finance. What did we go for? A financial tracking web application that helps users upload their latest reciepts and tabulate their expenditures in a detailed breakdown of different expense categories. This project won the Top Project Award at the MLH AI Hackfest 2023.</p>
            </div>
          </div>
          <div className="portfolio-item">
            <div className="portfolio-image">
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/AVWhU_kU328"
                title="YouTube video player"
                frameBorder={0}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div className="portfolio-description">
              <h3 className="spaceStyleSubheadingPortfolio">Monday.com AI Based Telegram Bot Service</h3>
              <p className="spaceStyleTextPortfolio">We were given 24 HOURS to create a web service for the Monday.com platform. To enhance user experience, we developed a web application for a Telegram Bot which helps generate and schedule important messages for our users on individual chats or groups. This service largely helps users in organizing their day to day business/interpersonal tasks.</p>
            </div>
          </div>
          <div className="portfolio-item">
            <div className="portfolio-image">
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/AtUXtNG24TU"
                title="YouTube video player"
                frameBorder={0}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div className="portfolio-description">
              <h3 className="spaceStyleSubheadingPortfolio">Outfit Designing Web Application</h3>
              <p className="spaceStyleTextPortfolio">We wanted to help society through our creation, but only had 24 HOURS? As we were trying to look for ideas we remembered the recently celebrated Pride month. So, we decided to do something for the LGBTQ community. And through this website, we not only raise awareness about the LGBTQ society but also donate customized clothes to charities.
              </p>
            </div>
          </div>
          
        </div>
      </div>
    </>
  );
};
